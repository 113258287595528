import { useState, useEffect, useMemo, useCallback } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useMutation } from "react-query";
import { useAuth } from "../context/Auth";
import { useMediaQuery } from "react-responsive";
import { useServizi } from "../hooks/useServizi";
import { useClienti } from "../hooks/useClienti";
import { useAutisti } from "../hooks/useAutisti";
import { useMezzi } from "../hooks/useMezzi";
import { useReferenti } from "../hooks/useReferenti";
import { useDebounce } from "../hooks/useDebounce";
import dateFormat from "dateformat";
import moment from "moment/min/moment-with-locales";
import useEnsureLogin from "../hooks/useEnsureLogin";
import * as XLSX from "xlsx-js-style";
import pb from "../lib/pocketbase";

export default function Servizi() {
  pb.autoCancellation(false);

  // Stato form inserimento & modifica servizio
  const { register, handleSubmit, reset } = useForm();
  const { mutate, isError, error } = useMutation(inserisciServizio);
  const [showForm, setShowForm] = useState(false);
  // Stato modifica servizio
  const [currentSer, setCurrentSer] = useState(null);
  const [servizioInModifica, setServizioInModifica] = useState(null);

  // BOOOO
  const [tipoServizio, setTipoServizio] = useState("");

  const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });
  // Filtro Date mobile
  const [dataFilter, setDataFilter] = useState(moment());
  const dataDebounced = useDebounce(dataFilter, 25);
  const dataFormattata = useMemo(() => {
    return dataFilter.format("yyyy-MM-DD");
  }, [dataFilter]);

  // Struttura dati per salvare in file excel
  const [data, setData] = useState([]);

  // Array di valori per i select preriempiti validi
  const tipoSer = ["Transfer", "Disposizione"];
  const tipoMez = [
    "Luxury sedan",
    "Normal sedan",
    "Luxury van",
    "Mini bus",
    "Green sedan",
  ];
  const oreDisp = ["2", "4", "6", "8", "10", "24"];
  const modPag = [
    "Carta di credito",
    "Carta di credito aziendale",
    "Carta di credito da remoto",
    "Contanti",
    "Conto",
    "Fattura",
    "PayPal",
  ];
  const selIVA = ["10", "Seleziona l'IVA"];
  const statFat = ["Da fatturare", "Fatturato"];
  const statSer = ["Prenotato", "In corso", "Concluso"];

  // Utente loggato
  const user = useAuth();

  // Utente per le query
  const userDatabaseOn = useMemo(() => {
    // if (user === undefined) return "";
    return user.tag === "SuperAdmin" ? user.id : user.creato_da;
  }, [user]);

  // Filtro servizi
  const formFiltro = useForm({ mode: "onChange" });
  const watchFiltroDebounced = useDebounce(
    useWatch({ control: formFiltro.control }),
    200
  );

  // Controllo per svg se data fine < data inizio
  const startDate = new Date(formFiltro.watch("partenza_da"));
  const endDate = new Date(formFiltro.watch("partenza_a"));

  // Gestione colori per lo stato servizio (colora la cella nella tabella desktop)
  const getStatusClass = (status) => {
    switch (status) {
      case "Prenotato":
        return "!bg-green-300/90 border-b border-[#d8dce4]";
      case "In corso":
        return "!bg-blue-300/90 border-b border-[#d8dce4]";
      case "Concluso":
        return "!bg-red-300/90 border-b border-[#d8dce4]";
      default:
        return "";
    }
  };

  // Gestione filtro servizi con sovrascrittura data isMobile
  const filtroServizi = useMemo(() => {
    if (isMobile) {
      return {
        ...watchFiltroDebounced,
        partenza_da: moment(dataDebounced).startOf("day").toISOString(),
        partenza_a: moment(dataDebounced).endOf("day").toISOString(),
      };
    }
    return watchFiltroDebounced;
  }, [isMobile, watchFiltroDebounced, dataDebounced]);

  //TODO: aggiungere uno useMemo per far notare l'errore tra la data di inizio e fine nei filtri

  // Dati query
  const {
    data: servizi,
    isLoading: isServiziLoading,
    isError: isServiziError,
    error: serviziError,
    refresh: refreshServizi,
  } = useServizi({ user, filter: filtroServizi, isMobile });

  // Servizio corrente per condividi
  const targetServizio = servizi
    ? servizi.find((element) => element.id === currentSer)
    : null;

  const {
    data: clienti,
    isLoading: isClientiLoading,
    isError: isClientiError,
    error: clientiError,
    refresh: refreshClienti,
  } = useClienti({ user });

  const {
    data: autisti,
    isLoading: isAutistiLoading,
    isError: isAutistiError,
    error: autistiError,
    refresh: refreshAutisti,
  } = useAutisti({ user });

  const {
    data: mezzi,
    isLoading: isMezziLoading,
    isError: isMezziError,
    error: mezziError,
    refresh: refreshMezzi,
  } = useMezzi({ user });

  const {
    data: referenti,
    isLoading: isReferentiLoading,
    isError: isReferentiError,
    error: referentiError,
    refresh: refreshReferenti,
  } = useReferenti({ user });

  const handleTipoServizioChange = (e) => {
    setTipoServizio(e.target.value);
  };

  function handleShow() {
    setShowForm(!showForm);
    reset();
  }

  function indietroTasto() {
    setServizioInModifica(null);
    handleShow();
  }

  const timeCheck = useMemo(() => {
    const startDate = new Date(user.orarioApertura);
    const endDate = new Date(user.orarioChiusura);
    const checkDate = new Date(
      `2022-01-01T${moment().locale("it").format("LTS")}`
    );

    return startDate <= checkDate && checkDate <= endDate;
  }, [user]);

  // TODO: forse può diventare useMemo o useEffect?
  function scriviCollaboratoreImportoIvato(e) {
    var impB = document
      .getElementById("collabImportoBase")
      .value.replace(",", ".");
    var impBparsato = parseFloat(impB);
    var impEsatto = impBparsato * 1.1;
    var impC = document.getElementById("collabImportoIvato");
    impC.value = impEsatto.toFixed(2).toString();
  }

  // TODO: forse può diventare useMemo o useEffect?
  function scriviCollaboratoreImporto(e) {
    var impB = document
      .getElementById("collabImportoIvato")
      .value.replace(",", ".");
    var impBparsato = parseFloat(impB);
    var impEsatto = impBparsato / 1.1;
    var impC = document.getElementById("collabImportoBase");
    impC.value = impEsatto.toFixed(2).toString();
  }

  // TODO: forse può diventare useMemo o useEffect?
  function scriviImportoIvato(e) {
    var impB = document.getElementById("importoBase").value.replace(",", ".");
    var impBparsato = parseFloat(impB);
    var impEsatto = impBparsato * 1.1;
    var impC = document.getElementById("importoCalcolato");
    impC.value = impEsatto.toFixed(2).toString();
  }

  // TODO: forse può diventare useMemo o useEffect?
  function scriviImporto(e) {
    var impB = document
      .getElementById("importoCalcolato")
      .value.replace(",", ".");
    var impBparsato = parseFloat(impB);
    var impEsatto = impBparsato / 1.1;
    var impC = document.getElementById("importoBase");
    impC.value = impEsatto.toFixed(2).toString();
  }

  // blocca la possibilità di modificare l'importo al dipendente se il servizio è concluso o se il metodo di pagamento è != da "Contanti" o "Carta di credito"
  function bloccaImporto(e) {
    if (
      user?.tag === "Dipendente" &&
      e !== "Carta di credito" &&
      e !== "Contanti"
    ) {
      document.getElementById("importoBase").disabled = true;
      document.getElementById("importoCalcolato").disabled = true;
    } else if (e === "Concluso" && user?.tag === "Dipendente") {
      document.getElementById("importoBase").disabled = true;
      document.getElementById("importoCalcolato").disabled = true;
    } else {
      document.getElementById("importoBase").disabled = false;
      document.getElementById("importoCalcolato").disabled = false;
    }
  }

  const onClickModificaServizio = useCallback(
    (id) => {
      const servizio = servizi.find((servizio) => servizio.id === id);
      console.log(id, servizio);

      setCurrentSer(servizio?.id);
      setServizioInModifica(servizi.find((servizio) => servizio.id === id));
      handleShow();
    },
    [servizi]
  );

  function formatDateTimeForPocketbase(dateTime) {
    //funziona tutto solo da cambiare perche si :3
    const dateObj = new Date(dateTime);

    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");

    const hours = String(dateObj.getHours()).padStart(2, "0");
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");
    const seconds = String(dateObj.getSeconds()).padStart(2, "0");

    const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;

    return formattedDateTime;
  }

  // TODO: Vedere se posso unire i metodi di modifica e creazione
  // TODO: Gestione errori tipo di connessione e feedback all'utente magari con un toast
  // let action = pb.collection('servizio').create;
  // if (servizioInModifica)
  //     action = pb.collection('servizio').update;
  // await action

  // async function insModServizi(data) {
  //     if (data.referente == 0) data.referente = "";
  //     if (data.mezzo == 0) data.mezzo = "";
  //     if (data.autista == 0) data.autista = "";
  //     if (data.tipo_servizio != "Disposizione") data.ore_disposizione = ""; // Nulla
  //     if (data.partenza) data.partenza = formatDateTimeForPocketbase(data.partenza);
  //     const datiDaInserire = {
  //         ...data,
  //         "user": userDatabaseOn
  //     }
  //     if (servizioInModifica) {
  //         await pb.collection('servizio').update(currentSer, datiDaInserire);
  //         setServizioInModifica(null);
  //     }
  //     else {
  //         await pb.collection('servizio').create(currentSer, datiDaInserire);
  //     }
  //     refreshServizi();
  //     handleShow();
  // }

  async function modificaServizio(data) {
    if (data.tipo_servizio != "Disposizione") data.ore_disposizione = ""; // Nulla
    if (data.partenza)
      data.partenza = formatDateTimeForPocketbase(data.partenza);
    console.log("MODIFICA", data);
    await pb.collection("servizio").update(currentSer, {
      ...data,
      user: userDatabaseOn,
    });
    setServizioInModifica(null);
    refreshServizi();
    handleShow();
  }

  async function eliminaServizio() {
    await pb.collection("servizio").delete(currentSer);
    refreshServizi();
    setCurrentSer(null);
    if (isMobile) indietroTasto();
  }

  async function sulClick(data) {
    !!servizioInModifica ? modificaServizio(data) : mutate(data);
  }

  async function inserisciServizio(data) {
    if (data.referente == 0) data.referente = "";
    if (data.mezzo == 0) data.mezzo = "";
    if (data.autista == 0) data.autista = "";
    if (data.tipo_servizio != "Disposizione") data.ore_disposizione = ""; // Nulla
    if (data.partenza)
      data.partenza = formatDateTimeForPocketbase(data.partenza);
    console.log("INSERISCI", data);
    await pb.collection("servizio").create({
      ...data,
      user: userDatabaseOn,
    });
    refreshServizi();
    handleShow();
  }

  const esportaFile = () => {
    // TODO: modal di intermezzo che spiega cosa fa il bottone e chiede quali colonne si vuole mettere nel file excel (non so se fattibile utlima parte); header in grassetto; somma della colonna prezzo in automatico (possibile?)

    // document.getElementById('modalCondividi').showModal()

    //TODO: gestire "Fattura nel prezzo"
    const newData = servizi.map((servizio) => ({
      DATA: dateFormat(servizio.partenza, "dd/mm/yyyy", "Z"),
      ORA: dateFormat(servizio.partenza, "HH:MM", "Z"),
      PARTENZA: servizio.luogo_partenza,
      DESTINAZIONE: servizio.luogo_arrivo,
      CLIENTE:
        userDatabaseOn &&
        servizio.expand.cliente &&
        servizio.expand.cliente.ragione_sociale_alias,
      "N. PAX": servizio.numero_passeggeri,
      PASSEGGERO: servizio.passeggero,
      PREZZO: ["Contanti", "Paypal", "Conto"].includes(
        servizio.modalita_pagamento
      )
        ? +servizio.importo
        : +servizio.importo_ivato,
      DARE: servizio.dare,
      AVERE: servizio.avere,
      NOTE: servizio.note_report,
    }));

    // Crea un workbook e converte i dati in un foglio unico
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(newData);

    // Trova il range della tabella
    const range = XLSX.utils.decode_range(worksheet["!ref"]);

    // style alla riga header
    for (let C = range.s.c; C <= range.e.c; C++) {
      const headerCell = XLSX.utils.encode_cell({ r: 0, c: C });
      if (worksheet[headerCell]) {
        worksheet[headerCell].s = {
          font: { bold: true, color: { rgb: "FFFFFF" } },
          fill: {
            patternType: "solid",
            fgColor: { rgb: "000000" },
          },
          border: {
            top: { style: "thin", color: { rgb: "000000" } },
            bottom: { style: "thin", color: { rgb: "000000" } },
            left: { style: "thin", color: { rgb: "000000" } },
            right: { style: "thin", color: { rgb: "000000" } },
          },
        };
      }
    }

    // style al resto della tabella
    for (let R = range.s.r + 1; R <= range.e.r; R++) {
      // skip riga header
      for (let C = range.s.c; C <= range.e.c; C++) {
        const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
        if (worksheet[cellAddress]) {
          worksheet[cellAddress].s = {
            border: {
              top: { style: "thin", color: { rgb: "000000" } },
              bottom: { style: "thin", color: { rgb: "000000" } },
              left: { style: "thin", color: { rgb: "000000" } },
              right: { style: "thin", color: { rgb: "000000" } },
            },
            alignment: {
              horizontal: "center",
              vertical: "center",
              wrapText: true,
            },
          };
        }
      }
    }

    // Aggiunge il foglio al workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Genera il file Excel e crea un Blob object (oggetto simil-file, immutabile usato per i download da browser)
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    // Crea un <a> come link per scaricare e setta il nome
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `Tabella ${moment().locale("it").format("LLL")}.xlsx`;

    // Butta il link sul body, simula un click per scaricare e rimuove il link
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {!showForm && (
        <>
          {/* visualizzazione desktop */}
          {/* visualizzazione desktop */}
          {/* visualizzazione desktop */}

          <div className="hidden lg:block self-stretch">
            <div className="collapse collapse-plus bg-base-700 items-center">
              <div>
                {user.tag === "Admin" || user.tag === "SuperAdmin" ? (
                  <button
                    onClick={handleShow}
                    className="btn btn-outline btn-sm"
                  >
                    {" "}
                    Inserisci nuovo servizio{" "}
                  </button>
                ) : (
                  ""
                )}
                {user.tag === "Cliente" && timeCheck ? (
                  <button
                    onClick={handleShow}
                    className="btn btn-outline btn-sm"
                  >
                    {" "}
                    Inserisci nuovo servizio{" "}
                  </button>
                ) : (
                  ""
                )}
                {user.tag === "Admin" || user.tag === "SuperAdmin" ? (
                  <button
                    onClick={esportaFile}
                    disabled={!servizi}
                    className="btn btn-outline btn-sm m-8"
                  >
                    ‎
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="icon icon-tabler icons-tabler-outline icon-tabler-share-2"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <path d="M8 9H7a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2h-1M12 14V3M9 6l3-3 3 3" />
                    </svg>{" "}
                    Esporta tabella
                  </button>
                ) : (
                  ""
                )}
              </div>

              <input type="checkbox" />
              <div className="collapse-title text-xl font-medium m-4 border-2 border-solid rounded-md border-black">
                Mostra/Nascondi filtri
              </div>

              <div className="collapse-content col-span-2">
                <form className="grid grid-cols-2 gap-4 items-center m-4 p-2 border-2 border-solid rounded-md border-black self-stretch">
                  <div className="md:flex md:items-center">
                    <label className="md:w-1/3 text-right mr-2">
                      {" "}
                      Tipo di servizio:{" "}
                    </label>
                    <select
                      defaultValue=""
                      {...formFiltro.register("tipo_servizio")}
                      className="md:w-2/3 select select-bordered select-xs"
                    >
                      <option label="Seleziona il tipo di servizio" value="" />
                      {tipoSer.map((value) => (
                        <option key={value} value={value} label={value} />
                      ))}
                    </select>
                  </div>

                  <div className="md:flex md:items-center">
                    <label className="md:w-1/3 text-right mr-2">
                      {" "}
                      Cliente:{" "}
                    </label>
                    <select
                      defaultValue=""
                      {...formFiltro.register("cliente")}
                      className="md:w-2/3 select select-bordered select-xs"
                    >
                      <option value="" label="Seleziona il cliente" />
                      {clienti &&
                        clienti.map((cliente) => (
                          <option key={cliente.id} value={cliente.id}>
                            {cliente.ragione_sociale_alias}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="md:flex md:items-center">
                    <label className="md:w-1/3 text-right mr-2">
                      {" "}
                      Data inizio:{" "}
                    </label>
                    <input
                      id="data_inizio"
                      {...formFiltro.register("partenza_da")}
                      className="md:w-2/3 input input-bordered input-xs"
                      type="date"
                      placeholder="Data"
                    />
                  </div>

                  <div className="md:flex md:items-center">
                    {endDate < startDate ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        stroke="#FF0000"
                        strokeWidth="1.5"
                        className="w-6 h-6"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
                        />
                      </svg>
                    ) : (
                      ""
                    )}
                    <label className="md:w-1/3 text-right mr-2">
                      {" "}
                      Data fine:
                    </label>
                    <input
                      id="data_fine"
                      {...formFiltro.register("partenza_a")}
                      className="md:w-2/3 input input-bordered input-xs"
                      type="date"
                      placeholder="Data"
                    />
                  </div>

                  <div className="md:flex md:items-center">
                    <label className="md:w-1/3 text-right mr-2">
                      {" "}
                      Partenza:
                    </label>
                    <input
                      {...formFiltro.register("luogo_partenza")}
                      className="md:w-2/3 input input-bordered input-xs"
                      type="text"
                      placeholder="Partenza"
                    />
                  </div>

                  <div className="md:flex md:items-center">
                    <label className="md:w-1/3 text-right mr-2">
                      {" "}
                      Destinazione:
                    </label>
                    <input
                      {...formFiltro.register("luogo_arrivo")}
                      className="md:w-2/3 input input-bordered input-xs"
                      type="text"
                      placeholder="Destinazione"
                    />
                  </div>

                  <div className="md:flex md:items-center">
                    <label className="md:w-1/3 text-right mr-2">
                      {" "}
                      Passeggero:
                    </label>
                    <input
                      {...formFiltro.register("passeggero")}
                      className="md:w-2/3 input input-bordered input-xs"
                      type="text"
                      placeholder="Passeggero"
                    />
                  </div>

                  <div className="md:flex md:items-center">
                    <label className="md:w-1/3 text-right mr-2">
                      {" "}
                      Modalità di pagamento:
                    </label>
                    <select
                      defaultValue=""
                      {...formFiltro.register("modalita_pagamento")}
                      className="md:w-2/3 select select-bordered select-xs"
                    >
                      <option
                        value=""
                        label="Seleziona il metodo di pagamento"
                      />
                      {modPag.map((value) => (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="md:flex md:items-center">
                    <label className="md:w-1/3 text-right mr-2">
                      {" "}
                      Autista:
                    </label>
                    <select
                      defaultValue=""
                      {...formFiltro.register("autista")}
                      className="md:w-2/3 select select-bordered select-xs"
                    >
                      <option value="" label="Seleziona l'autista" />
                      {autisti &&
                        autisti.map((autista) => (
                          <option key={autista.id} value={autista.id}>
                            {autista.alias}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="md:flex md:items-center">
                    <label className="md:w-1/3 text-right mr-2"> Mezzo: </label>
                    <select
                      defaultValue=""
                      {...formFiltro.register("mezzo")}
                      className="md:w-2/3 select select-bordered select-xs"
                    >
                      <option value="" label="Seleziona il mezzo" />
                      {mezzi &&
                        mezzi.map((mezzo) => (
                          <option key={mezzo.id} value={mezzo.id}>
                            {mezzo.macchina}
                          </option>
                        ))}
                    </select>
                  </div>
                  <button
                    type="reset"
                    className="btn btn-outline btn-md justify-self-center col-span-2"
                    onClick={() =>
                      formFiltro.reset({
                        tipo_servizio: "",
                        cliente: "",
                        partenza_da: "",
                        partenza_a: "",
                        luogo_partenza: "",
                        luogo_arrivo: "",
                        passeggero: "",
                        modalita_pagamento: "",
                        autista: null,
                        mezzo: "",
                      })
                    }
                  >
                    {" "}
                    Pulisci filtri{" "}
                  </button>
                </form>
              </div>
            </div>
          </div>

          {isServiziLoading && (
            <div className="m-4 loading loading-spinner loading-md">
              Caricamento...
            </div>
          )}
          <table className="table-auto hidden lg:table table-md self-stretch table-compact table-zebra mx-2">
            <thead>
              <tr>
                <th className="!bg-stone-300" scope="col">
                  {" "}
                  Servizio{" "}
                </th>
                <th className="!bg-stone-300" scope="col">
                  {" "}
                  Data{" "}
                </th>
                <th className="!bg-stone-300" scope="col">
                  {" "}
                  Partenza{" "}
                </th>
                <th className="!bg-stone-300" scope="col">
                  {" "}
                  Destinazione{" "}
                </th>
                <th className="!bg-stone-300" scope="col">
                  {" "}
                  Cliente{" "}
                </th>
                <th className="!bg-stone-300" scope="col">
                  {" "}
                  Pax{" "}
                </th>
                <th className="!bg-stone-300" scope="col">
                  {" "}
                  Passeggero{" "}
                </th>
                <th className="!bg-stone-300" scope="col">
                  {" "}
                  Metodo di pagamento{" "}
                </th>
                <th className="!bg-stone-300" scope="col">
                  {" "}
                  Prezzo{" "}
                </th>
                <th className="!bg-stone-300" scope="col">
                  {" "}
                  Autista{" "}
                </th>
                <th className="!bg-stone-300" scope="col">
                  {" "}
                  Mezzo{" "}
                </th>
                {user.tag != "Dipendente" && (
                  <th className="!bg-stone-300" scope="col">
                    {" "}
                    Dare{" "}
                  </th>
                )}
                <th className="!bg-stone-300" scope="col">
                  {" "}
                  Avere{" "}
                </th>
                <th className="!bg-stone-300" scope="col">
                  {" "}
                  Note{" "}
                </th>
                {user.tag != "Cliente" && (
                  <th className="!bg-stone-300" scope="col">
                    {" "}
                  </th>
                )}
              </tr>
            </thead>
            <tbody className="space-y-4">
              {servizi &&
                servizi.map((servizio) => (
                  <tr key={servizio.id}>
                    <td
                      className={`whitespace-normal break-normal ${getStatusClass(
                        servizio.stato_servizio
                      )}`}
                    >
                      {" "}
                      <p> {servizio.tipo_mezzo} </p>
                    </td>
                    <td className="whitespace-normal break-normal">
                      {" "}
                      <p>
                        {" "}
                        {servizio.partenza
                          ? dateFormat(
                              servizio.partenza,
                              "dd/mm/yyyy HH:MM",
                              "Z"
                            )
                          : ""}{" "}
                      </p>
                    </td>
                    <td className="whitespace-normal break-normal">
                      {" "}
                      <p> {servizio.luogo_partenza} </p>
                    </td>
                    <td className="whitespace-normal break-normal">
                      {" "}
                      <p> {servizio.luogo_arrivo} </p>
                    </td>
                    <td className="whitespace-normal break-normal">
                      {" "}
                      <p>
                        {" "}
                        {userDatabaseOn &&
                          servizio.expand.cliente &&
                          servizio.expand.cliente.ragione_sociale_alias}{" "}
                      </p>
                    </td>
                    <td className="whitespace-normal break-normal">
                      {" "}
                      <p> {servizio.numero_passeggeri} </p>
                    </td>
                    <td className="whitespace-normal break-normal">
                      {" "}
                      <p> {servizio.passeggero} </p>
                    </td>
                    <td className="whitespace-normal break-normal">
                      {" "}
                      <p> {servizio.modalita_pagamento} </p>
                    </td>
                    <td className="whitespace-normal break-normal">
                      {" "}
                      <p>
                        {" "}
                        {servizio.modalita_pagamento === "Contanti"
                          ? +servizio.importo
                          : +servizio.importo_ivato}{" "}
                      </p>
                    </td>
                    <td className="whitespace-normal break-normal">
                      {" "}
                      <p>
                        {" "}
                        {servizio.expand.autista &&
                          servizio.expand.autista.alias}{" "}
                      </p>
                    </td>
                    <td className="whitespace-normal break-normal">
                      {" "}
                      <p>
                        {" "}
                        {servizio.expand.mezzo &&
                          servizio.expand.mezzo.macchina}{" "}
                      </p>
                    </td>
                    {user.tag != "Dipendente" && (
                      <td className="whitespace-normal break-normal">
                        {" "}
                        <p> {servizio.dare} </p>
                      </td>
                    )}
                    <td className="whitespace-normal break-normal">
                      {" "}
                      <p> {servizio.avere} </p>
                    </td>
                    <td className="whitespace-normal break-normal">
                      {" "}
                      <p> {servizio.note_report} </p>
                    </td>
                    {user.tag === "Admin" || user.tag === "SuperAdmin" ? (
                      <td>
                        <button
                          className="btn btn-outline btn-xs m-1"
                          onClick={() => onClickModificaServizio(servizio.id)}
                        >
                          {" "}
                          Modifica{" "}
                        </button>
                        <button
                          className="btn btn-outline btn-xs hover:bg-red-700 m-1"
                          onClick={() => {
                            document.getElementById("modalElimina").showModal();
                            setCurrentSer(servizio.id);
                          }}
                        >
                          {" "}
                          Elimina{" "}
                        </button>
                        <button
                          className="btn btn-outline btn-xs m-1"
                          onClick={() => {
                            document
                              .getElementById("modalCondividi")
                              .showModal();
                          }}
                        >
                          ‎{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            className="w-5 h-5"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z"
                            />
                          </svg>
                        </button>
                      </td>
                    ) : (
                      ""
                    )}
                    {user.tag === "Dipendente" && (
                      <td>
                        {" "}
                        <button
                          className="btn btn-outline btn-xs"
                          onClick={() => onClickModificaServizio(servizio.id)}
                        >
                          {" "}
                          Apri{" "}
                        </button>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>

          {servizi && (
            <div className="hidden lg:flex flex-row items-center m-4 p-2 border-2 border-solid rounded-md border-black self-stretch">
              <span className="flex-initial">
                {" "}
                Totale servizi: {servizi.length}
              </span>
              <span className="flex-grow">
                {" "}
                Totale prezzo: €
                {servizi.reduce(
                  (acc, curr) =>
                    acc +
                    (curr.modalita_pagamento === "Contanti"
                      ? +curr.importo
                      : +curr.importo_ivato),
                  0
                )}{" "}
              </span>
              {user.tag != "Dipendente" && (
                <span className="flex-grow">
                  {" "}
                  Totale dare: €
                  {servizi.reduce((acc, curr) => acc + +curr.dare, 0)}{" "}
                </span>
              )}
              <span className="flex-grow">
                {" "}
                Totale avere: €
                {servizi.reduce((acc, curr) => acc + +curr.avere, 0)}{" "}
              </span>
            </div>
          )}

          {/* visualizzazione mobile */}
          {/* visualizzazione mobile */}
          {/* visualizzazione mobile */}

          {isMobile && (
            <div className="mb-4 contents">
              <div className="my-2">
                {user.tag === "Admin" || user.tag === "SuperAdmin" ? (
                  <button
                    onClick={handleShow}
                    className="btn btn-outline btn-sm"
                  >
                    {" "}
                    Inserisci nuovo servizio{" "}
                  </button>
                ) : (
                  ""
                )}
                {user.tag === "Cliente" && timeCheck ? (
                  <button
                    onClick={handleShow}
                    className="btn btn-outline btn-sm"
                  >
                    {" "}
                    Inserisci nuovo servizio{" "}
                  </button>
                ) : (
                  ""
                )}
              </div>

              <div className="w-screen bg-info lg:hidden flex flex-row justify-between items-center">
                <button
                  className="btn btn-ghost p-4 text-white flex-grow"
                  onClick={() =>
                    setDataFilter((prev) => moment(prev).subtract(1, "d"))
                  }
                >
                  {" "}
                  Indietro{" "}
                </button>
                <input
                  className="input input-sm bg-transparent text-white dark:[color-scheme:dark]"
                  type="date"
                  value={dataFormattata}
                  onChange={(e) => {
                    setDataFilter(moment(e.target.value));
                  }}
                />
                <button
                  className="btn btn-ghost p-4 text-white flex-grow"
                  onClick={() =>
                    setDataFilter((prev) => moment(prev).add(1, "d"))
                  }
                >
                  {" "}
                  Avanti{" "}
                </button>
              </div>

              {isServiziLoading && (
                <div>
                  <span className="p-4 loading loading-spinner loading-md"></span>
                </div>
              )}
              {isServiziError && <div> Errore: {serviziError.message} </div>}
              {servizi &&
                servizi.map((servizio) => (
                  <div
                    onClick={() => onClickModificaServizio(servizio.id)}
                    key={servizio.id}
                    className="self-stretch border border-black lg:hidden"
                    // className="self-stretch shadow-custom m-2 rounded-lg lg:hidden"
                  >
                    <div className="grid grid-flow-row grid-cols-2 px-2">
                      <div className="flex items-stretch text-left ml-2 my-2 break-normal items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          className="w-6 h-6"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                          />
                        </svg>
                        <p className="ml-1 break-normal">
                          {" "}
                          {servizio.partenza
                            ? dateFormat(
                                servizio.partenza,
                                "dd/mm/yyyy HH:MM",
                                "Z"
                              )
                            : ""}{" "}
                        </p>
                      </div>

                      {/* <div className="flex items-stretch text-right mr-4 my-2 break-normal justify-end"><button className="btn btn-outline btn-xs">‎ <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeWidth="1.5" className="w-5 h-5" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z"/></svg></button></div> */}
                      <div className="flex items-stretch text-left ml-2 my-2 break-normal items-center">
                        {servizio.stato_servizio === "Concluso" && (
                          <p className="ml-1 break-normal bg-red-500">
                            Concluso
                          </p>
                        )}
                        {servizio.stato_servizio === "In corso" && (
                          <p className="ml-1 break-normal bg-yellow-500">
                            In corso
                          </p>
                        )}
                        {servizio.stato_servizio === "Prenotato" && (
                          <p className="ml-1 break-normal bg-green-500">
                            Prenotato
                          </p>
                        )}
                        {/* <button className="btn btn-md bg-black hover:bg-white text-white hover:text-black btn-outline m-1" onClick={(e) => { e.preventDefault(); document.getElementById('modalCondividi').showModal() }}> <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeWidth="1.5" className="w-6 h-6" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z" /></svg> </button> */}
                      </div>

                      <div className="flex items-stretch text-left ml-2 mb-2 break-normal items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          className="w-6 h-6"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                          />
                        </svg>
                        <p className="ml-1 break-normal">
                          {servizio.luogo_partenza}
                        </p>
                      </div>

                      <div className="flex items-stretch text-left ml-2 mb-2 break-normal items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          className="w-6 h-6"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 3v1.5M3 21v-6m0 0 2.77-.693a9 9 0 0 1 6.208.682l.108.054a9 9 0 0 0 6.086.71l3.114-.732a48.524 48.524 0 0 1-.005-10.499l-3.11.732a9 9 0 0 1-6.085-.711l-.108-.054a9 9 0 0 0-6.208-.682L3 4.5M3 15V4.5"
                          />
                        </svg>
                        <p className="ml-1 break-normal">
                          {servizio.luogo_arrivo}
                        </p>
                      </div>

                      <div className="flex items-stretch text-left ml-2 mb-2 break-normal items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.25"
                          className="icon icon-tabler icons-tabler-outline icon-tabler-user-scan"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" />
                          <path d="M10 9a2 2 0 1 0 4 0 2 2 0 0 0-4 0M4 8V6a2 2 0 0 1 2-2h2M4 16v2a2 2 0 0 0 2 2h2M16 4h2a2 2 0 0 1 2 2v2M16 20h2a2 2 0 0 0 2-2v-2M8 16a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2" />
                        </svg>
                        <p className="ml-1 break-normal">
                          {userDatabaseOn &&
                            servizio.expand.cliente &&
                            servizio.expand.cliente.ragione_sociale_alias}
                        </p>
                      </div>

                      <div className="flex items-stretch text-left ml-2 mb-2 break-normal items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          className="w-6 h-6"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
                          />
                        </svg>
                        <p className="ml-1 break-normal">
                          {servizio.numero_passeggeri}
                        </p>
                      </div>

                      <div className="flex items-stretch text-left ml-2 mb-2 break-normal items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          className="w-6 h-6"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z"
                          />
                        </svg>
                        <p className="ml-1 break-normal">
                          {servizio.expand.autista &&
                            servizio.expand.autista.alias}
                        </p>
                      </div>

                      <div className="flex items-stretch text-left ml-2 mb-2 break-normal items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.25"
                          className="icon icon-tabler icons-tabler-outline icon-tabler-car"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" />
                          <path d="M5 17a2 2 0 1 0 4 0 2 2 0 1 0-4 0M15 17a2 2 0 1 0 4 0 2 2 0 1 0-4 0" />
                          <path d="M5 17H3v-6l2-5h9l4 5h1a2 2 0 0 1 2 2v4h-2m-4 0H9m-6-6h15m-6 0V6" />
                        </svg>
                        <p className="ml-1 break-normal">
                          {servizio.expand.mezzo &&
                            servizio.expand.mezzo.macchina}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </>
      )}

      {/* Inserimento/Modifica dati PC*/}
      {/* Inserimento/Modifica dati PC*/}
      {/* Inserimento/Modifica dati PC*/}

      {showForm && !isMobile && (
        <>
          <div className="w-screen m-auto">
            <form
              className="w-full bg-white shadow-md rounded px-6 pt-6 pb-8 my-8 space-y-4"
              onSubmit={handleSubmit(sulClick)}
            >
              <div className="flex flex-col justify-center items-center">
                <h1 className="mt-4 text-3xl font-semibold">
                  {" "}
                  {!!servizioInModifica
                    ? "MODIFICA I DATI DEL SERVIZIO"
                    : "INSERISCI I DATI DEL SERVIZIO"}{" "}
                </h1>
              </div>

              <div className="divider" />

              <label className="md:flex md:items-center justify-center">
                Stato servizio:
                <select
                  className="select select-bordered select-xs w-screen max-w-xl ml-2"
                  {...register("stato_servizio")}
                  onChange={(e) => {
                    if (e.target.value === "Concluso")
                      document.getElementById("dataFineAuto").value = moment()
                        .locale("en-ca")
                        .format("L");
                  }}
                  defaultValue={servizioInModifica?.stato_servizio ?? ""}
                  disabled={
                    servizioInModifica?.stato_servizio === "Concluso" &&
                    user?.tag === "Dipendente"
                  }
                >
                  <option label="Seleziona lo stato del servizio" value="" />
                  {statSer.map((value) => (
                    <option
                      key={value}
                      value={value}
                      style={{
                        color:
                          value === "Concluso"
                            ? "red"
                            : value === "In corso"
                            ? "blue"
                            : "green",
                        fontWeight: "bold",
                      }}
                    >
                      {value}
                    </option>
                  ))}
                </select>
              </label>

              {user.tag != "Dipendente" && (
                <label className="md:flex md:items-center justify-center">
                  Cliente:
                  <select
                    className="select select-bordered select-xs w-screen max-w-xl ml-2"
                    required
                    {...register("cliente")}
                    defaultValue={servizioInModifica?.cliente ?? ""}
                  >
                    <option label="Seleziona il cliente" value="" />
                    {clienti.map((cliente) => (
                      <option key={cliente.id} value={cliente.id}>
                        {cliente.ragione_sociale_alias}
                      </option>
                    ))}
                  </select>
                </label>
              )}

              <div className="grid grid-flow-row grid-cols-2 border-2 border-solid rounded-md border-black self-stretch p-4">
                <div className="md:flex md:items-center mb-6">
                  <label className="md:w-1/3 text-right mr-2">
                    Tipo di servizio:
                  </label>
                  <select
                    className="md:w-2/3 select select-bordered select-xs w-full max-w-xl"
                    {...register("tipo_servizio")}
                    defaultValue={servizioInModifica?.tipo_servizio ?? ""}
                    onChange={handleTipoServizioChange}
                    disabled={user?.tag === "Dipendente"}
                  >
                    <option label="Seleziona il tipo di servizio" value="" />
                    {tipoSer.map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="md:flex md:items-center  mb-6">
                  <label className="md:w-1/3 text-right mr-2">
                    {" "}
                    Tipo di mezzo:{" "}
                  </label>
                  <select
                    className="md:w-2/3 select select-bordered select-xs w-full max-w-xl"
                    {...register("tipo_mezzo")}
                    defaultValue={servizioInModifica?.tipo_mezzo ?? ""}
                    disabled={user?.tag === "Dipendente"}
                  >
                    <option label="Seleziona il tipo di mezzo" value="" />
                    {tipoMez.map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="md:flex md:items-center mb-6">
                  <label className="md:w-1/3 text-right mr-2">
                    {" "}
                    Ore disposizione:{" "}
                  </label>
                  <select
                    className="md:w-2/3 select select-bordered select-xs w-full max-w-xl"
                    {...register("ore_disposizione")}
                    defaultValue={servizioInModifica?.ore_disposizione ?? ""}
                    disabled={
                      tipoServizio !== "Disposizione" ||
                      user?.tag === "Dipendente"
                    }
                  >
                    <option label="Seleziona le ore a disposizione" value="" />
                    {oreDisp.map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="md:flex md:items-center mb-6">
                  <label className="md:w-1/3 text-right mr-2">
                    {" "}
                    Referente:{" "}
                  </label>
                  <select
                    className="md:w-2/3 select select-bordered select-xs w-full max-w-xl"
                    {...register("referente")}
                    defaultValue={servizioInModifica?.referente ?? ""}
                    disabled={user?.tag === "Dipendente"}
                  >
                    <option label="Seleziona il referente" value="" />
                    {referenti.map((referente) => (
                      <option key={referente.id} value={referente.id}>
                        {referente.nome}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="md:flex md:items-center mb-6">
                  <label className="md:w-1/3 text-right mr-2"> Data: </label>
                  <input
                    required
                    className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                    type="datetime-local"
                    placeholder="Data"
                    {...register("partenza")}
                    defaultValue={
                      servizioInModifica?.partenza
                        ? new Date(servizioInModifica.partenza)
                            .toISOString()
                            .slice(0, 16)
                        : ""
                    }
                    disabled={user?.tag === "Dipendente"}
                  />
                </div>

                <div className="md:flex md:items-center mb-6"></div>

                <div className="md:flex md:items-center">
                  <label className="md:w-1/3 text-right mr-2">
                    {" "}
                    Partenza:{" "}
                  </label>
                  <input
                    required
                    className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                    type="text"
                    placeholder="Partenza"
                    {...register("luogo_partenza")}
                    defaultValue={servizioInModifica?.luogo_partenza ?? ""}
                    disabled={user?.tag === "Dipendente"}
                  />
                </div>

                <div className="md:flex md:items-center">
                  <label className="md:w-1/3 text-right mr-2">
                    {" "}
                    Destinazione:{" "}
                  </label>
                  <input
                    required
                    className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                    type="text"
                    placeholder="Destinazione"
                    {...register("luogo_arrivo")}
                    defaultValue={servizioInModifica?.luogo_arrivo ?? ""}
                    disabled={user?.tag === "Dipendente"}
                  />
                </div>
              </div>

              {/* forse si può mettere flex flex-col a tutte le label che wrappano tutto e poi marcare meglio l'etichetta per distinguerla dall'input */}
              {user.tag != "Dipendente" && (
                <label className="flex flex-col mb-6 text-black font-extrabold justify-center items-center">
                  Descrizione
                  <textarea
                    className="textarea textarea-bordered textarea-md h-screen w-screen min-h-80 max-h-80 max-w-screen-lg m-4"
                    type="text"
                    placeholder="Descrizione"
                    {...register("descrizione_viaggio")}
                    defaultValue={servizioInModifica?.descrizione_viaggio ?? ""}
                  />
                </label>
              )}

              <div className="grid grid-flow-row grid-cols-2 items-center">
                <div className="p-4">
                  <div className="md:flex md:items-center mb-6">
                    <label className="md:w-1/3 text-right mr-2">
                      {" "}
                      Passeggero:{" "}
                    </label>
                    <input
                      className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                      type="text"
                      placeholder="Passeggero"
                      {...register("passeggero")}
                      defaultValue={servizioInModifica?.passeggero ?? ""}
                      disabled={user?.tag === "Dipendente"}
                    />
                  </div>

                  <div className="md:flex md:items-center mb-6">
                    <label className="md:w-1/3 text-right mr-2">
                      {" "}
                      Numero passeggeri:{" "}
                    </label>
                    <input
                      className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                      type="number"
                      min="0"
                      step="any"
                      inputMode="decimal"
                      pattern="[0-9]*"
                      placeholder="Numero passeggeri"
                      {...register("numero_passeggeri")}
                      defaultValue={servizioInModifica?.numero_passeggeri ?? ""}
                      disabled={
                        servizioInModifica?.stato_servizio === "Concluso" &&
                        user?.tag === "Dipendente"
                      }
                    />
                  </div>

                  <div className="md:flex md:items-center mb-6">
                    <label className="md:w-1/3 text-right mr-2">
                      {" "}
                      Telefono passeggero:{" "}
                    </label>
                    <input
                      className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                      type="text"
                      placeholder="Telefono passeggero"
                      {...register("telefono_passeggero")}
                      defaultValue={
                        servizioInModifica?.telefono_passeggero ?? ""
                      }
                      disabled={user?.tag === "Dipendente"}
                    />
                  </div>

                  <div className="md:flex md:items-center mb-6">
                    <label className="md:w-1/3 text-right mr-2">
                      {" "}
                      Numero volo:{" "}
                    </label>
                    <input
                      className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                      type="text"
                      placeholder="Numero volo"
                      {...register("numero_volo")}
                      defaultValue={servizioInModifica?.numero_volo ?? ""}
                      disabled={user?.tag === "Dipendente"}
                    />
                  </div>

                  <div className="divider" />

                  <div className="md:flex md:items-center mb-6">
                    <label className="md:w-1/3 text-right mr-2">
                      {" "}
                      Autista:{" "}
                    </label>
                    <select
                      className="md:w-2/3 select select-bordered select-xs w-full max-w-xl"
                      {...register("autista")}
                      defaultValue={servizioInModifica?.autista ?? ""}
                      disabled={user?.tag === "Dipendente"}
                    >
                      <option label="Seleziona l'autista" value="" />
                      {autisti.map((autista) => (
                        <option key={autista.id} value={autista.id}>
                          {autista.alias}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="md:flex md:items-center mb-6">
                    <label className="md:w-1/3 text-right mr-2"> Mezzo: </label>
                    <select
                      className="md:w-2/3 select select-bordered select-xs w-full max-w-xl"
                      {...register("mezzo")}
                      defaultValue={servizioInModifica?.mezzo ?? ""}
                      disabled={user?.tag === "Dipendente"}
                    >
                      <option label="Seleziona il mezzo" value="" />
                      {mezzi.map((mezzo) => (
                        <option key={mezzo.id} value={mezzo.id}>
                          {mezzo.macchina}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="md:flex md:items-center mb-6">
                    <label className="md:w-1/3 text-right mr-2">
                      {" "}
                      Autista occasionale:{" "}
                    </label>
                    <input
                      className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                      type="text"
                      placeholder="Autista occasionale"
                      {...register("autista_occasionale")}
                      defaultValue={
                        servizioInModifica?.autista_occasionale ?? ""
                      }
                      disabled={user?.tag === "Dipendente"}
                    />
                  </div>

                  <div className="md:flex md:items-center mb-6">
                    <label className="md:w-1/3 text-right mr-2">
                      {" "}
                      Km iniziali servizio:{" "}
                    </label>
                    <input
                      className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                      type="number"
                      step="any"
                      inputMode="decimal"
                      pattern="[0-9]*"
                      placeholder="Km iniziali servizio"
                      {...register("km_iniziali_servizio")}
                      defaultValue={
                        servizioInModifica?.km_iniziali_servizio ?? ""
                      }
                      disabled={
                        servizioInModifica?.stato_servizio === "Concluso" &&
                        user?.tag === "Dipendente"
                      }
                    />
                  </div>

                  <div className="md:flex md:items-center mb-6">
                    <label className="md:w-1/3 text-right mr-2">
                      {" "}
                      Km finali servizio:{" "}
                    </label>
                    <input
                      className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                      type="number"
                      step="any"
                      inputMode="decimal"
                      pattern="[0-9]*"
                      placeholder="Km finali servizio"
                      {...register("km_finali_servizio")}
                      defaultValue={
                        servizioInModifica?.km_finali_servizio ?? ""
                      }
                      disabled={
                        servizioInModifica?.stato_servizio === "Concluso" &&
                        user?.tag === "Dipendente"
                      }
                    />
                  </div>
                </div>

                <div className="border-2 border-solid rounded-md border-black self-stretch p-4">
                  {user.tag != "Dipendente" && (
                    <div className="md:flex md:items-center mb-6">
                      <label className="md:w-1/3 text-right mr-2">
                        {" "}
                        Preventivo:{" "}
                      </label>
                      <input
                        className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                        type="text"
                        placeholder="Preventivo"
                        {...register("preventivo")}
                        defaultValue={servizioInModifica?.preventivo ?? ""}
                      />
                    </div>
                  )}

                  <div className="md:flex md:items-center mb-6">
                    <label className="md:w-1/3 text-right mr-2">
                      {" "}
                      Modalità di pagamento:{" "}
                    </label>
                    <select
                      className="md:w-2/3 select select-bordered select-xs w-full max-w-xl"
                      required
                      {...register("modalita_pagamento", {
                        onChange: (e) => {
                          bloccaImporto(e.target.value);
                        },
                      })}
                      defaultValue={
                        servizioInModifica?.modalita_pagamento ?? ""
                      }
                      disabled={
                        servizioInModifica?.stato_servizio === "Concluso" &&
                        user?.tag === "Dipendente"
                      }
                    >
                      <option
                        label="Seleziona la modalità di pagamento"
                        value=""
                      />
                      {modPag.map((value) => (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Prova per mettere selector limitato a dipendente (rivedere idea, non va bene perchè non puo vedfere fattura ma non poterlo scegliere, forse limitare click?) */}
                  {/* <div className="md:flex md:items-center mb-6">
                                <label className="md:w-1/3 text-right mr-2"> Modalità di pagamento: </label>
                                {user.tag != "Dipendente" ? <select className="md:w-2/3 select select-bordered select-xs w-full max-w-xl" required {...register("modalita_pagamento")} defaultValue={servizioInModifica?.modalita_pagamento ?? ""} disabled={servizioInModifica?.stato_servizio === 'Concluso' && user?.tag === 'Dipendente'} >
                                    <option label="Seleziona la modalità di pagamento" value="" />
                                    {modPag.map(value => (
                                        <option key={value} value={value}>
                                            {value}
                                        </option>
                                    ))}
                                </select> :
                                <select className="md:w-2/3 select select-bordered select-xs w-full max-w-xl" required {...register("modalita_pagamento")} defaultValue={servizioInModifica?.modalita_pagamento ?? ""} disabled={servizioInModifica?.stato_servizio === 'Concluso' && user?.tag === 'Dipendente'} >
                                    <option key="Carta di credito" value="Carta di credito">
                                        Carta di credito
                                    </option>
                                    <option key="Contanti" value="Contanti">
                                        Contanti
                                    </option>
                                </select>}
                            </div> */}

                  <div className="md:flex md:items-center mb-6">
                    <label className="md:w-1/3 text-right mr-2">
                      {" "}
                      Importo:{" "}
                    </label>
                    <input
                      id="importoBase"
                      className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                      type="number"
                      min="0"
                      step="any"
                      inputMode="decimal"
                      pattern="[0-9]*"
                      {...register("importo", {
                        onBlur: (e) => {
                          scriviImportoIvato(e.target.value);
                        },
                      })}
                      placeholder="Importo"
                      defaultValue={servizioInModifica?.importo ?? ""}
                      disabled={
                        (servizioInModifica?.stato_servizio === "Concluso" &&
                          user?.tag === "Dipendente") ||
                        (user?.tag === "Dipendente" &&
                          servizioInModifica?.modalita_pagamento !==
                            "Carta di credito" &&
                          servizioInModifica?.modalita_pagamento !== "Contanti")
                      }
                    />
                  </div>

                  <div className="md:flex md:items-center mb-6">
                    <label className="md:w-1/3 text-right mr-2">
                      {" "}
                      Importo ivato:{" "}
                    </label>
                    <input
                      id="importoCalcolato"
                      className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                      type="number"
                      min="0"
                      step="any"
                      inputMode="decimal"
                      pattern="[0-9]*"
                      {...register("importo_ivato", {
                        onBlur: (e) => {
                          scriviImporto(e);
                        },
                      })}
                      placeholder="Importo ivato"
                      defaultValue={servizioInModifica?.importo_ivato ?? ""}
                      disabled={
                        (servizioInModifica?.stato_servizio === "Concluso" &&
                          user?.tag === "Dipendente") ||
                        (user?.tag === "Dipendente" &&
                          servizioInModifica?.modalita_pagamento !==
                            "Carta di credito" &&
                          servizioInModifica?.modalita_pagamento !== "Contanti")
                      }
                    />
                  </div>

                  <div className="md:flex md:items-center mb-6">
                    <label className="md:w-1/3 text-right mr-2"> IVA: </label>
                    <select
                      className="md:w-2/3 select select-bordered select-xs w-full max-w-xl"
                      {...register("iva")}
                      defaultValue={servizioInModifica?.iva ?? selIVA[0]}
                      disabled={user?.tag === "Dipendente"}
                    >
                      {selIVA.map((value) => (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      ))}
                    </select>
                  </div>

                  {user.tag != "Dipendente" && (
                    <div className="md:flex md:items-center mb-6">
                      <label className="md:w-1/3 text-right mr-2">
                        {" "}
                        Spese:{" "}
                      </label>
                      <input
                        className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                        type="text"
                        placeholder="Spese"
                        {...register("spese")}
                        defaultValue={servizioInModifica?.spese ?? ""}
                      />
                    </div>
                  )}

                  {user.tag != "Dipendente" && (
                    <div className="md:flex md:items-center mb-6">
                      <label className="md:w-1/3 text-right mr-2">
                        {" "}
                        Prezzo coll.:{" "}
                      </label>
                      <input
                        id="collabImportoBase"
                        className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                        type="number"
                        min="0"
                        step="any"
                        inputMode="decimal"
                        pattern="[0-9]*"
                        placeholder="Prezzo collaboratore"
                        {...register("prezzo_collaboratore", {
                          onBlur: (e) => {
                            scriviCollaboratoreImportoIvato(e.target.value);
                          },
                        })}
                        defaultValue={
                          servizioInModifica?.prezzo_collaboratore ?? ""
                        }
                      />
                    </div>
                  )}

                  {user.tag != "Dipendente" && (
                    <div className="md:flex md:items-center mb-6">
                      <label className="md:w-1/3 text-right mr-2">
                        {" "}
                        Prezzo coll. ivato:{" "}
                      </label>
                      <input
                        id="collabImportoIvato"
                        className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                        type="number"
                        min="0"
                        step="any"
                        inputMode="decimal"
                        pattern="[0-9]*"
                        placeholder="Prezzo collaboratore ivato"
                        {...register("prezzo_collaboratore_iva", {
                          onBlur: (e) => {
                            scriviCollaboratoreImporto(e.target.value);
                          },
                        })}
                        defaultValue={
                          servizioInModifica?.prezzo_collaboratore_iva ?? ""
                        }
                      />
                    </div>
                  )}

                  {user.tag != "Dipendente" && (
                    <div className="md:flex md:items-center mb-6">
                      <label className="md:w-1/3 text-right mr-2">
                        {" "}
                        Sconto:{" "}
                      </label>
                      <input
                        className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                        type="text"
                        placeholder="Sconto"
                        {...register("sconto")}
                        defaultValue={servizioInModifica?.sconto ?? ""}
                      />
                    </div>
                  )}

                  {user.tag != "Dipendente" && (
                    <div className="md:flex md:items-center mb-6">
                      <label className="md:w-1/3 text-right mr-2">
                        {" "}
                        Dare:{" "}
                      </label>
                      <input
                        className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                        type="text"
                        placeholder="Dare"
                        {...register("dare")}
                        defaultValue={servizioInModifica?.dare ?? ""}
                      />
                    </div>
                  )}

                  <div className="md:flex md:items-center">
                    <label className="md:w-1/3 text-right mr-2"> Avere: </label>
                    <input
                      className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                      type="text"
                      placeholder="Avere"
                      {...register("avere")}
                      defaultValue={servizioInModifica?.avere ?? ""}
                      disabled={user?.tag === "Dipendente"}
                    />
                  </div>
                </div>
              </div>

              <label className="flex flex-col mb-6 text-black font-extrabold justify-center items-center">
                Note servizio
                <textarea
                  className="textarea textarea-bordered textarea-md h-screen w-screen min-h-80 max-h-80 max-w-screen-lg m-4"
                  type="text"
                  placeholder="Note"
                  {...register("note_servizio")}
                  defaultValue={servizioInModifica?.note_servizio ?? ""}
                  disabled={
                    servizioInModifica?.stato_servizio === "Concluso" &&
                    user?.tag === "Dipendente"
                  }
                />
              </label>

              <div className="grid-cols-2 border-2 border-solid rounded-md border-black self-stretch p-4">
                <div className="grid grid-flow-row grid-cols-2">
                  <div className="md:flex md:items-center mb-6">
                    <label className="md:w-1/3 text-right mr-2">
                      {" "}
                      Data fine:{" "}
                    </label>
                    <input
                      id="dataFineAuto"
                      className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                      type="datetime-local"
                      placeholder="Data fine"
                      {...register("data_fine")}
                      defaultValue={
                        servizioInModifica?.data_fine
                          ? new Date(servizioInModifica.data_fine)
                              .toISOString()
                              .split("T")[0]
                          : ""
                      }
                      disabled={
                        tipoServizio !== "Disposizione" ||
                        (servizioInModifica?.stato_servizio === "Concluso" &&
                          user?.tag === "Dipendente")
                      }
                    />
                  </div>

                  <div className="md:flex md:items-center mb-6">
                    <label className="md:w-1/3 text-right mr-2">
                      {" "}
                      Chilometri:{" "}
                    </label>
                    <input
                      className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                      type="text"
                      placeholder="Chilometri"
                      {...register("chilometri")}
                      defaultValue={servizioInModifica?.chilometri ?? ""}
                      disabled={
                        tipoServizio !== "Disposizione" ||
                        (servizioInModifica?.stato_servizio === "Concluso" &&
                          user?.tag === "Dipendente")
                      }
                    />
                  </div>

                  <div className="md:flex md:items-center">
                    <label className="md:w-1/3 text-right mr-2"> Ore: </label>
                    <input
                      className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                      type="text"
                      placeholder="Ore"
                      {...register("ore")}
                      defaultValue={servizioInModifica?.ore ?? ""}
                      disabled={
                        tipoServizio !== "Disposizione" ||
                        (servizioInModifica?.stato_servizio === "Concluso" &&
                          user?.tag === "Dipendente")
                      }
                    />
                  </div>

                  <div className="md:flex md:items-center">
                    <label className="md:w-1/3 text-right mr-2">
                      {" "}
                      Ore di attesa:{" "}
                    </label>
                    <input
                      className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                      type="text"
                      placeholder="Ore di attesa"
                      {...register("ore_attesa")}
                      defaultValue={servizioInModifica?.ore_attesa ?? ""}
                      disabled={
                        tipoServizio !== "Disposizione" ||
                        (servizioInModifica?.stato_servizio === "Concluso" &&
                          user?.tag === "Dipendente")
                      }
                    />
                  </div>
                </div>
              </div>

              {user.tag != "Dipendente" && (
                <label className="flex flex-col mb-6 text-black font-extrabold justify-center items-center">
                  Note report
                  <textarea
                    className="textarea textarea-bordered textarea-md h-screen w-screen min-h-80 max-h-80 max-w-screen-lg m-4"
                    type="text"
                    placeholder="Note"
                    {...register("note_report")}
                    defaultValue={servizioInModifica?.note_report ?? ""}
                  />
                </label>
              )}

              {user.tag != "Dipendente" && (
                <div className="border-2 border-solid rounded-md border-black self-stretch p-4">
                  <div className="grid grid-flow-row grid-cols-2">
                    <div className="md:flex md:items-center">
                      <label className="md:w-1/3 text-right mr-2">
                        {" "}
                        Descrizione fattura:{" "}
                      </label>
                      <input
                        className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                        type="text"
                        placeholder="Descrizione fattura"
                        {...register("descrizione_fattura")}
                        defaultValue={
                          servizioInModifica?.descrizione_fattura ?? ""
                        }
                      />
                    </div>

                    <div className="md:flex md:items-center">
                      <label className="md:w-1/3 text-right mr-2">
                        {" "}
                        Stato fattura:{" "}
                      </label>
                      <select
                        className="md:w-2/3 select select-bordered select-xs w-full max-w-xl"
                        {...register("stato_fattura")}
                        defaultValue={servizioInModifica?.stato_fattura ?? ""}
                      >
                        <option
                          label="Seleziona lo stato della fattura"
                          value=""
                        />
                        {statFat.map((value) => (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              )}

              <div className="flex flex-row-reverse float-right sticky bottom-8 max-w-fit m-8">
                <button
                  className="btn btn-lg bg-black hover:bg-white text-white hover:text-black btn-outline m-1"
                  onClick={indietroTasto}
                >
                  {" "}
                  Indietro{" "}
                </button>
                <button
                  className="btn btn-lg bg-black hover:bg-white text-white hover:text-black btn-outline m-1"
                  type="submit"
                >
                  {" "}
                  Salva{" "}
                </button>
              </div>
            </form>
          </div>
        </>
      )}

      {/* Inserimento/Modifica dati mobile*/}
      {/* Inserimento/Modifica dati mobile*/}
      {/* Inserimento/Modifica dati mobile*/}

      {showForm && isMobile && (
        <>
          <div className="w-screen m-auto">
            <form
              className="w-full bg-white shadow-md rounded px-6 pt-6 pb-8 my-8 space-y-4"
              onSubmit={handleSubmit(sulClick)}
            >
              <div className="flex flex-col justify-center items-center">
                <h1 className="mt-4 text-3xl font-semibold">
                  {" "}
                  {!!servizioInModifica
                    ? "MODIFICA I DATI DEL SERVIZIO"
                    : "INSERISCI I DATI DEL SERVIZIO"}{" "}
                </h1>
              </div>

              <div className="divider" />

              <div className="md:flex md:items-center mb-6">
                <label className="md:w-1/3 text-right mr-2">
                  Stato servizio:
                </label>
                <select
                  className="md:w-2/3 select select-bordered select-xs w-full max-w-xl"
                  {...register("stato_servizio")}
                  onChange={(e) => {
                    if (e.target.value === "Concluso")
                      document.getElementById("dataFineAuto").value = moment()
                        .locale("en-ca")
                        .format("L");
                  }}
                  defaultValue={servizioInModifica?.stato_servizio ?? ""}
                  disabled={
                    servizioInModifica?.stato_servizio === "Concluso" &&
                    user?.tag === "Dipendente"
                  }
                >
                  <option label="Seleziona lo stato del servizio" value="" />
                  {statSer.map((value) => (
                    <option
                      key={value}
                      value={value}
                      style={{
                        color: value === "Concluso" ? "red" : "black",
                        fontWeight: "bold",
                      }}
                    >
                      {value}
                    </option>
                  ))}
                </select>
              </div>

              {user.tag != "Dipendente" && (
                <div className="md:flex md:items-center mb-6">
                  <label className="md:w-1/3 text-right mr-2">Cliente:</label>
                  <select
                    className="md:w-2/3 select select-bordered select-xs w-full max-w-xl"
                    required
                    {...register("cliente")}
                    defaultValue={servizioInModifica?.cliente ?? ""}
                  >
                    <option label="Seleziona il cliente" value="" />
                    {clienti.map((cliente) => (
                      <option key={cliente.id} value={cliente.id}>
                        {cliente.ragione_sociale_alias}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              <div className="border-2 border-solid rounded-md border-black self-stretch p-4">
                <div className="md:flex md:items-center mb-6">
                  <label className="md:w-1/3 text-right mr-2">
                    Tipo di servizio:
                  </label>
                  <select
                    className="md:w-2/3 select select-bordered select-xs w-full max-w-xl"
                    {...register("tipo_servizio")}
                    defaultValue={servizioInModifica?.tipo_servizio ?? ""}
                    onChange={handleTipoServizioChange}
                    disabled={user?.tag === "Dipendente"}
                  >
                    <option label="Seleziona il tipo di servizio" value="" />
                    {tipoSer.map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="md:flex md:items-center  mb-6">
                  <label className="md:w-1/3 text-right mr-2">
                    {" "}
                    Tipo di mezzo:{" "}
                  </label>
                  <select
                    className="md:w-2/3 select select-bordered select-xs w-full max-w-xl"
                    {...register("tipo_mezzo")}
                    defaultValue={servizioInModifica?.tipo_mezzo ?? ""}
                    disabled={user?.tag === "Dipendente"}
                  >
                    <option label="Seleziona il tipo di mezzo" value="" />
                    {tipoMez.map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="md:flex md:items-center mb-6">
                  <label className="md:w-1/3 text-right mr-2">
                    {" "}
                    Ore disposizione:{" "}
                  </label>
                  <select
                    className="md:w-2/3 select select-bordered select-xs w-full max-w-xl"
                    {...register("ore_disposizione")}
                    defaultValue={servizioInModifica?.ore_disposizione ?? ""}
                    disabled={
                      tipoServizio !== "Disposizione" ||
                      user?.tag === "Dipendente"
                    }
                  >
                    <option label="Seleziona le ore a disposizione" value="" />
                    {oreDisp.map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="md:flex md:items-center mb-6">
                  <label className="md:w-1/3 text-right mr-2">
                    {" "}
                    Referente:{" "}
                  </label>
                  <select
                    className="md:w-2/3 select select-bordered select-xs w-full max-w-xl"
                    {...register("referente")}
                    defaultValue={servizioInModifica?.referente ?? ""}
                    disabled={user?.tag === "Dipendente"}
                  >
                    <option label="Seleziona il referente" value="" />
                    {referenti.map((referente) => (
                      <option key={referente.id} value={referente.id}>
                        {referente.nome}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="md:flex md:items-center mb-6">
                  <label className="md:w-1/3 text-right mr-2"> Data: </label>
                  <input
                    required
                    className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                    type="datetime-local"
                    placeholder="Data"
                    {...register("partenza")}
                    defaultValue={
                      servizioInModifica?.partenza
                        ? new Date(servizioInModifica.partenza)
                            .toISOString()
                            .slice(0, 16)
                        : ""
                    }
                    disabled={user?.tag === "Dipendente"}
                  />
                </div>

                <div className="md:flex md:items-center mb-6">
                  <label className="md:w-1/3 text-right mr-2">
                    {" "}
                    Partenza:{" "}
                  </label>
                  <input
                    required
                    className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                    type="text"
                    placeholder="Partenza"
                    {...register("luogo_partenza")}
                    defaultValue={servizioInModifica?.luogo_partenza ?? ""}
                    disabled={user?.tag === "Dipendente"}
                  />
                </div>

                <div className="md:flex md:items-center">
                  <label className="md:w-1/3 text-right mr-2">
                    {" "}
                    Destinazione:{" "}
                  </label>
                  <input
                    required
                    className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                    type="text"
                    placeholder="Destinazione"
                    {...register("luogo_arrivo")}
                    defaultValue={servizioInModifica?.luogo_arrivo ?? ""}
                    disabled={user?.tag === "Dipendente"}
                  />
                </div>
              </div>

              {/* forse si può mettere flex flex-col a tutte le label che wrappano tutto e poi marcare meglio l'etichetta per distinguerla dall'input */}
              {user.tag != "Dipendente" && (
                <div className="md:flex md:items-center mb-6 justify-center">
                  <label className="flex flex-col text-black font-extrabold">
                    Descrizione
                    <textarea
                      className="textarea textarea-bordered textarea-md h-screen min-h-80 max-h-80 max-w-screen-lg m-4"
                      type="text"
                      placeholder="Descrizione"
                      {...register("descrizione_viaggio")}
                      defaultValue={
                        servizioInModifica?.descrizione_viaggio ?? ""
                      }
                    />
                  </label>
                </div>
              )}

              <div className="p-4">
                <div className="md:flex md:items-center mb-6">
                  <label className="md:w-1/3 text-right mr-2">
                    {" "}
                    Passeggero:{" "}
                  </label>
                  <input
                    className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                    type="text"
                    placeholder="Passeggero"
                    {...register("passeggero")}
                    defaultValue={servizioInModifica?.passeggero ?? ""}
                    disabled={user?.tag === "Dipendente"}
                  />
                </div>

                <div className="md:flex md:items-center mb-6">
                  <label className="md:w-1/3 text-right mr-2">
                    {" "}
                    Numero passeggeri:{" "}
                  </label>
                  <input
                    className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                    type="number"
                    min="0"
                    step="any"
                    inputMode="decimal"
                    pattern="[0-9]*"
                    placeholder="Numero passeggeri"
                    {...register("numero_passeggeri")}
                    defaultValue={servizioInModifica?.numero_passeggeri ?? ""}
                    disabled={
                      servizioInModifica?.stato_servizio === "Concluso" &&
                      user?.tag === "Dipendente"
                    }
                  />
                </div>

                <div className="md:flex md:items-center mb-6">
                  <label className="md:w-1/3 text-right mr-2">
                    {" "}
                    Telefono passeggero:{" "}
                  </label>
                  <input
                    className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                    type="text"
                    placeholder="Telefono passeggero"
                    {...register("telefono_passeggero")}
                    defaultValue={servizioInModifica?.telefono_passeggero ?? ""}
                    disabled={user?.tag === "Dipendente"}
                  />
                </div>

                <div className="md:flex md:items-center mb-6">
                  <label className="md:w-1/3 text-right mr-2">
                    {" "}
                    Numero volo:{" "}
                  </label>
                  <input
                    className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                    type="text"
                    placeholder="Numero volo"
                    {...register("numero_volo")}
                    defaultValue={servizioInModifica?.numero_volo ?? ""}
                    disabled={user?.tag === "Dipendente"}
                  />
                </div>

                <div className="divider" />

                <div className="md:flex md:items-center mb-6">
                  <label className="md:w-1/3 text-right mr-2"> Autista: </label>
                  <select
                    className="md:w-2/3 select select-bordered select-xs w-full max-w-xl"
                    {...register("autista")}
                    defaultValue={servizioInModifica?.autista ?? ""}
                    disabled={user?.tag === "Dipendente"}
                  >
                    <option label="Seleziona l'autista" value="" />
                    {autisti?.map((autista) => (
                      <option key={autista.id} value={autista.id}>
                        {autista.alias}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="md:flex md:items-center mb-6">
                  <label className="md:w-1/3 text-right mr-2"> Mezzo: </label>
                  <select
                    className="md:w-2/3 select select-bordered select-xs w-full max-w-xl"
                    {...register("mezzo")}
                    defaultValue={servizioInModifica?.mezzo ?? ""}
                    disabled={user?.tag === "Dipendente"}
                  >
                    <option label="Seleziona il mezzo" value="" />
                    {mezzi.map((mezzo) => (
                      <option key={mezzo.id} value={mezzo.id}>
                        {mezzo.macchina}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="md:flex md:items-center mb-6">
                  <label className="md:w-1/3 text-right mr-2">
                    {" "}
                    Autista occasionale:{" "}
                  </label>
                  <input
                    className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                    type="text"
                    placeholder="Autista occasionale"
                    {...register("autista_occasionale")}
                    defaultValue={servizioInModifica?.autista_occasionale ?? ""}
                    disabled={user?.tag === "Dipendente"}
                  />
                </div>

                <div className="md:flex md:items-center mb-6">
                  <label className="md:w-1/3 text-right mr-2">
                    {" "}
                    Km iniziali servizio:{" "}
                  </label>
                  <input
                    className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                    type="number"
                    step="any"
                    inputMode="decimal"
                    pattern="[0-9]*"
                    placeholder="Km iniziali servizio"
                    {...register("km_iniziali_servizio")}
                    defaultValue={
                      servizioInModifica?.km_iniziali_servizio ?? ""
                    }
                    disabled={
                      servizioInModifica?.stato_servizio === "Concluso" &&
                      user?.tag === "Dipendente"
                    }
                  />
                </div>

                <div className="md:flex md:items-center">
                  <label className="md:w-1/3 text-right mr-2">
                    {" "}
                    Km finali servizio:{" "}
                  </label>
                  <input
                    className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                    type="number"
                    step="any"
                    inputMode="decimal"
                    pattern="[0-9]*"
                    placeholder="Km finali servizio"
                    {...register("km_finali_servizio")}
                    defaultValue={servizioInModifica?.km_finali_servizio ?? ""}
                    disabled={
                      servizioInModifica?.stato_servizio === "Concluso" &&
                      user?.tag === "Dipendente"
                    }
                  />
                </div>
              </div>

              <div className="border-2 border-solid rounded-md border-black self-stretch p-4">
                {user.tag != "Dipendente" && (
                  <div className="md:flex md:items-center mb-6">
                    <label className="md:w-1/3 text-right mr-2">
                      {" "}
                      Preventivo:{" "}
                    </label>
                    <input
                      className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                      type="text"
                      placeholder="Preventivo"
                      {...register("preventivo")}
                      defaultValue={servizioInModifica?.preventivo ?? ""}
                    />
                  </div>
                )}

                <div className="md:flex md:items-center mb-6">
                  <label className="md:w-1/3 text-right mr-2">
                    {" "}
                    Modalità di pagamento:{" "}
                  </label>
                  <select
                    className="md:w-2/3 select select-bordered select-xs w-full max-w-xl"
                    required
                    {...register("modalita_pagamento", {
                      onChange: (e) => {
                        bloccaImporto(e.target.value);
                      },
                    })}
                    defaultValue={servizioInModifica?.modalita_pagamento ?? ""}
                    disabled={
                      servizioInModifica?.stato_servizio === "Concluso" &&
                      user?.tag === "Dipendente"
                    }
                  >
                    <option
                      label="Seleziona la modalità di pagamento"
                      value=""
                    />
                    {modPag.map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="md:flex md:items-center mb-6">
                  <label className="md:w-1/3 text-right mr-2"> Importo: </label>
                  <input
                    id="importoBase"
                    className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                    type="number"
                    min="0"
                    step="any"
                    inputMode="decimal"
                    pattern="[0-9]*"
                    {...register("importo", {
                      onBlur: (e) => {
                        scriviImportoIvato(e.target.value);
                      },
                    })}
                    placeholder="Importo"
                    defaultValue={servizioInModifica?.importo ?? ""}
                    disabled={
                      (servizioInModifica?.stato_servizio === "Concluso" &&
                        user?.tag === "Dipendente") ||
                      (user?.tag === "Dipendente" &&
                        servizioInModifica?.modalita_pagamento !==
                          "Carta di credito" &&
                        servizioInModifica?.modalita_pagamento !== "Contanti")
                    }
                  />
                </div>

                <div className="md:flex md:items-center mb-6">
                  <label className="md:w-1/3 text-right mr-2">
                    {" "}
                    Importo ivato:{" "}
                  </label>
                  <input
                    id="importoCalcolato"
                    className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                    type="number"
                    min="0"
                    step="any"
                    inputMode="decimal"
                    pattern="[0-9]*"
                    {...register("importo_ivato", {
                      onBlur: (e) => {
                        scriviImporto(e);
                      },
                    })}
                    placeholder="Importo ivato"
                    defaultValue={servizioInModifica?.importo_ivato ?? ""}
                    disabled={
                      (servizioInModifica?.stato_servizio === "Concluso" &&
                        user?.tag === "Dipendente") ||
                      (user?.tag === "Dipendente" &&
                        servizioInModifica?.modalita_pagamento !==
                          "Carta di credito" &&
                        servizioInModifica?.modalita_pagamento !== "Contanti")
                    }
                  />
                </div>

                <div className="md:flex md:items-center mb-6">
                  <label className="md:w-1/3 text-right mr-2"> IVA: </label>
                  <select
                    className="md:w-2/3 select select-bordered select-xs w-full max-w-xl"
                    {...register("iva")}
                    defaultValue={servizioInModifica?.iva ?? selIVA[0]}
                    disabled={user?.tag === "Dipendente"}
                  >
                    {selIVA.map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>

                {user.tag != "Dipendente" && (
                  <div className="md:flex md:items-center mb-6">
                    <label className="md:w-1/3 text-right mr-2"> Spese: </label>
                    <input
                      className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                      type="text"
                      placeholder="Spese"
                      {...register("spese")}
                      defaultValue={servizioInModifica?.spese ?? ""}
                    />
                  </div>
                )}

                {user.tag != "Dipendente" && (
                  <div className="md:flex md:items-center mb-6">
                    <label className="md:w-1/3 text-right mr-2">
                      {" "}
                      Prezzo coll.:{" "}
                    </label>
                    <input
                      id="collabImportoBase"
                      className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                      type="number"
                      min="0"
                      step="any"
                      inputMode="decimal"
                      pattern="[0-9]*"
                      placeholder="Prezzo collaboratore"
                      {...register("prezzo_collaboratore", {
                        onBlur: (e) => {
                          scriviCollaboratoreImportoIvato(e.target.value);
                        },
                      })}
                      defaultValue={
                        servizioInModifica?.prezzo_collaboratore ?? ""
                      }
                    />
                  </div>
                )}

                {user.tag != "Dipendente" && (
                  <div className="md:flex md:items-center mb-6">
                    <label className="md:w-1/3 text-right mr-2">
                      {" "}
                      Prezzo coll. ivato:{" "}
                    </label>
                    <input
                      id="collabImportoIvato"
                      className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                      type="number"
                      min="0"
                      step="any"
                      inputMode="decimal"
                      pattern="[0-9]*"
                      placeholder="Prezzo collaboratore ivato"
                      {...register("prezzo_collaboratore_iva", {
                        onBlur: (e) => {
                          scriviCollaboratoreImporto(e.target.value);
                        },
                      })}
                      defaultValue={
                        servizioInModifica?.prezzo_collaboratore_iva ?? ""
                      }
                    />
                  </div>
                )}

                {user.tag != "Dipendente" && (
                  <div className="md:flex md:items-center mb-6">
                    <label className="md:w-1/3 text-right mr-2">
                      {" "}
                      Sconto:{" "}
                    </label>
                    <input
                      className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                      type="text"
                      placeholder="Sconto"
                      {...register("sconto")}
                      defaultValue={servizioInModifica?.sconto ?? ""}
                    />
                  </div>
                )}

                {user.tag != "Dipendente" && (
                  <div className="md:flex md:items-center mb-6">
                    <label className="md:w-1/3 text-right mr-2"> Dare: </label>
                    <input
                      className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                      type="text"
                      placeholder="Dare"
                      {...register("dare")}
                      defaultValue={servizioInModifica?.dare ?? ""}
                    />
                  </div>
                )}

                <div className="md:flex md:items-center">
                  <label className="md:w-1/3 text-right mr-2"> Avere: </label>
                  <input
                    className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                    type="text"
                    placeholder="Avere"
                    {...register("avere")}
                    defaultValue={servizioInModifica?.avere ?? ""}
                    disabled={user?.tag === "Dipendente"}
                  />
                </div>
              </div>

              <div className="md:flex md:items-center mb-6 justify-center">
                <label className="flex flex-col text-black font-extrabold">
                  Note servizio
                  <textarea
                    className="textarea textarea-bordered textarea-md h-screen min-h-80 max-h-80 max-w-screen-lg m-4"
                    type="text"
                    placeholder="Note"
                    {...register("note_servizio")}
                    defaultValue={servizioInModifica?.note_servizio ?? ""}
                    disabled={
                      servizioInModifica?.stato_servizio === "Concluso" &&
                      user?.tag === "Dipendente"
                    }
                  />
                </label>
              </div>

              <div className="border-2 border-solid rounded-md border-black self-stretch p-4">
                <div className="md:flex md:items-center mb-6">
                  <label className="md:w-1/3 text-right mr-2">
                    {" "}
                    Data fine:{" "}
                  </label>
                  <input
                    id="dataFineAuto"
                    className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                    type="datetime-local"
                    placeholder="Data fine"
                    {...register("data_fine")}
                    defaultValue={
                      servizioInModifica?.data_fine
                        ? new Date(servizioInModifica.data_fine)
                            .toISOString()
                            .split("T")[0]
                        : ""
                    }
                    disabled={
                      tipoServizio !== "Disposizione" ||
                      (servizioInModifica?.stato_servizio === "Concluso" &&
                        user?.tag === "Dipendente")
                    }
                  />
                </div>

                <div className="md:flex md:items-center mb-6">
                  <label className="md:w-1/3 text-right mr-2">
                    {" "}
                    Chilometri:{" "}
                  </label>
                  <input
                    className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                    type="text"
                    placeholder="Chilometri"
                    {...register("chilometri")}
                    defaultValue={servizioInModifica?.chilometri ?? ""}
                    disabled={
                      tipoServizio !== "Disposizione" ||
                      (servizioInModifica?.stato_servizio === "Concluso" &&
                        user?.tag === "Dipendente")
                    }
                  />
                </div>

                <div className="md:flex md:items-center mb-6">
                  <label className="md:w-1/3 text-right mr-2"> Ore: </label>
                  <input
                    className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                    type="text"
                    placeholder="Ore"
                    {...register("ore")}
                    defaultValue={servizioInModifica?.ore ?? ""}
                    disabled={
                      tipoServizio !== "Disposizione" ||
                      (servizioInModifica?.stato_servizio === "Concluso" &&
                        user?.tag === "Dipendente")
                    }
                  />
                </div>

                <div className="md:flex md:items-center">
                  <label className="md:w-1/3 text-right mr-2">
                    {" "}
                    Ore di attesa:{" "}
                  </label>
                  <input
                    className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                    type="text"
                    placeholder="Ore di attesa"
                    {...register("ore_attesa")}
                    defaultValue={servizioInModifica?.ore_attesa ?? ""}
                    disabled={
                      tipoServizio !== "Disposizione" ||
                      (servizioInModifica?.stato_servizio === "Concluso" &&
                        user?.tag === "Dipendente")
                    }
                  />
                </div>
              </div>

              {user.tag != "Dipendente" && (
                <div className="md:flex md:items-center mb-6 justify-center">
                  <label className="flex flex-col text-black font-extrabold">
                    Note report
                    <textarea
                      className="textarea textarea-bordered textarea-md h-screen min-h-80 max-h-80 max-w-screen-lg m-4"
                      type="text"
                      placeholder="Note"
                      {...register("note_report")}
                      defaultValue={servizioInModifica?.note_report ?? ""}
                    />
                  </label>
                </div>
              )}

              {user.tag != "Dipendente" && (
                <div className="border-2 border-solid rounded-md border-black self-stretch p-4">
                  <div className="md:flex md:items-center mb-6">
                    <label className="md:w-1/3 text-right mr-2">
                      {" "}
                      Descrizione fattura:{" "}
                    </label>
                    <input
                      className="md:w-2/3 input input-bordered input-xs w-full max-w-xl"
                      type="text"
                      placeholder="Descrizione fattura"
                      {...register("descrizione_fattura")}
                      defaultValue={
                        servizioInModifica?.descrizione_fattura ?? ""
                      }
                    />
                  </div>

                  <div className="md:flex md:items-center">
                    <label className="md:w-1/3 text-right mr-2">
                      {" "}
                      Stato fattura:{" "}
                    </label>
                    <select
                      className="md:w-2/3 select select-bordered select-xs w-full max-w-xl"
                      {...register("stato_fattura")}
                      defaultValue={servizioInModifica?.stato_fattura ?? ""}
                    >
                      <option
                        label="Seleziona lo stato della fattura"
                        value=""
                      />
                      {statFat.map((value) => (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}

              {user.tag != "Dipendente" ? (
                <div className="flex flex-row-reverse sticky bottom-8 m-8 justify-center">
                  <button
                    className="btn btn-md bg-black hover:bg-white text-white hover:text-black btn-outline m-1 btn-error"
                    onClick={(e) => {
                      e.preventDefault();
                      document.getElementById("modalElimina").showModal();
                    }}
                  >
                    {" "}
                    Elimina{" "}
                  </button>
                  <button
                    className="btn btn-md bg-black hover:bg-white text-white hover:text-black btn-outline m-1"
                    onClick={(e) => {
                      e.preventDefault();
                      indietroTasto();
                    }}
                  >
                    {" "}
                    Indietro{" "}
                  </button>
                  <button
                    className="btn btn-md bg-black hover:bg-white text-white hover:text-black btn-outline m-1"
                    type="submit"
                  >
                    {" "}
                    Salva{" "}
                  </button>
                  <button
                    className="btn btn-md bg-black hover:bg-white text-white hover:text-black btn-outline m-1"
                    onClick={(e) => {
                      e.preventDefault();
                      document.getElementById("modalCondividi").showModal();
                    }}
                  >
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      className="w-6 h-6"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z"
                      />
                    </svg>{" "}
                  </button>
                </div>
              ) : (
                <div className="flex flex-row-reverse sticky bottom-8 m-8 justify-end">
                  <button
                    className="btn btn-md bg-black hover:bg-white text-white hover:text-black btn-outline m-1"
                    type="submit"
                  >
                    {" "}
                    Salva{" "}
                  </button>
                  <button
                    className="btn btn-md bg-black hover:bg-white text-white hover:text-black btn-outline m-1"
                    onClick={indietroTasto}
                  >
                    {" "}
                    Chiudi{" "}
                  </button>
                </div>
              )}
            </form>
          </div>
        </>
      )}

      <dialog id="modalElimina" className="modal-middle rounded-md">
        <form method="dialog">
          <p className="p-5"> Sei sicuro di voler eliminare il servizio?</p>
          <button
            className="btn btn-outline btn-xs m-3"
            onClick={() => eliminaServizio()}
          >
            {" "}
            Conferma{" "}
          </button>
          <button className="btn btn-outline btn-xs m-3"> Annulla </button>
        </form>
      </dialog>

      <dialog id="modalCondividi" className="modal-middle rounded-md">
        <form method="dialog">
          <textarea
            className="textarea textarea-bordered textarea-md h-screen w-screen min-h-80 max-h-80 max-w-screen-lg m-4"
            type="text"
            readOnly
            value={
              targetServizio
                ? dateFormat(targetServizio.partenza, "dd/mm/yyyy", "Z") +
                  "\n" +
                  "Ore: " +
                  dateFormat(targetServizio.partenza, "HH:MM", "Z") +
                  "\n" +
                  "Partenza: " +
                  targetServizio.luogo_partenza +
                  "\n" +
                  "Destinazione: " +
                  targetServizio.luogo_arrivo +
                  "\n" +
                  "Passeggero: " +
                  targetServizio.passeggero +
                  "\n" +
                  "N. Pax: " +
                  targetServizio.numero_passeggeri +
                  "\n" +
                  "N. Volo: " +
                  targetServizio.numero_volo +
                  "\n" +
                  targetServizio.modalita_pagamento +
                  ": €" +
                  (["Contanti", "Paypal", "Conto"].includes(
                    targetServizio.modalita_pagamento
                  )
                    ? targetServizio.importo
                    : targetServizio.modalita_pagamento === "Fattura"
                    ? targetServizio.importo + " + IVA"
                    : targetServizio.importo_ivato) +
                  "\n" +
                  (targetServizio.modalita_pagamento === "Contanti"
                    ? "Carta di credito: €" +
                      targetServizio.importo_ivato +
                      "\n"
                    : "") +
                  "Avere: €" +
                  targetServizio.avere
                : "Caricamento..."
            }
          />
          {/* div meglio di textarea??? */}
          {/* TODO: condividi va male su pc, devi prima modificare il servizio e poi puoi fare condividi */}
          {/* {currentSer ? <div>
                    {dateFormat(currentSer.partenza, 'dd/mm/yyyy HH:MM', "Z")}
                    Partenza: {currentSer.luogo_partenza}
                    Destinazione: {currentSer.luogo_arrivo}
                    Passeggero: {currentSer.passeggero}
                    N. Pax: {currentSer.numero_passeggeri}
                    N. Volo: {currentSer.numero_volo}
                    Contanti: € {currentSer.importo}
                    Carta di credito: € {currentSer.importo_ivato}
                    Avere: {currentSer.avere}
                </div> : "Caricamento..."} */}
          <button className="btn btn-outline btn-xs m-3"> Chiudi </button>
        </form>
      </dialog>
    </>
  );
}
